<template>
    <Modalwindow v-show="showModal" @close="closeModal" class="overflow-auto">
        <div class="modal__content">
            <div class="modal__header">
                <h2 class="text-2xl">Зачисление денежных средств</h2>
                <CloseButton class=" absolute top-5 right-5" @close="closeModal"></CloseButton>
            </div>
            <div class="py-5">
                <table class="mb-10">
                    <thead>
                    <th class="p-3 select-none">Юр.лицо</th>
                    <th class="p-3 select-none">УНП</th>
                    <th class="p-3 select-none">Договор</th>
                    <th class="p-3 select-none">Баланс</th>
                    <th class="p-3 select-none">Обновлен</th>
                    </thead>
                    <tbody>
                    <tr class="dark:bg-gray-800 bg-white select-none">
                        <td class="p-3 ">{{ organization.uf_name }}</td>
                        <td class="p-3 ">{{ organization.uf_unp }}</td>
                        <td class="p-3 ">{{ organization.uf_contract }}</td>
                        <td class="p-3 ">{{ balance.balance }}</td>
                        <td class="p-3 ">{{ balance.updated }}</td>
                    </tr>
                    </tbody>
                </table>
                <div class="flex mb-0.5">
                    <p class="select-none w-full my-auto">Номер платежа</p>
                    <div class="w-full">
                        <input class="w-full" type="text" v-model.trim="number" placeholder="Номер платежа">
                        <small class="text-red-600"
                               v-if="!$v.number.required && $v.number.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>
                </div>
                <div class="flex mb-0.5">
                    <p class="select-none w-full my-auto">УНП</p>
                    <div class="w-full">
                        <input disabled class="w-full" type="text" v-model.trim="unp" placeholder="УНП">
                        <small class="text-red-600"
                               v-if="!$v.unp.required && $v.unp.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>
                </div>
                <div class="flex mb-0.5">
                    <p class="select-none w-full my-auto">Сумма в бел.рублях</p>
                    <div class="w-full">
                        <input class="w-full" type="text" v-model.trim="sum" placeholder="Сумма">
                        <small class="text-red-600"
                               v-if="!$v.sum.required && $v.sum.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>
                </div>
                <div class="flex mb-0.5">
                    <p class="select-none w-full my-auto">Назначение платежа</p>
                    <div class="w-full">
                        <input class="w-full" type="text" v-model.trim="details" placeholder="Назначение платежа">
                        <small class="text-red-600"
                               v-if="!$v.details.required && $v.details.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>

                    </div>
                </div>
                <div class="flex mb-0.5">
                    <p class="select-none w-full my-auto">Дата платежа</p>
                    <div class="w-full">
                        <input class="w-full" type="date" v-model.trim="date">
                        <small class="text-red-600"
                               v-if="!$v.date.required && $v.date.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>

                    </div>
                </div>
                <div class="flex my-10 confirm">
                    <p class="select-none w-1/2 my-auto">Для подтверждения операции введите указанный код: <span
                        class="text-blue-500">{{ code_text }}</span></p>
                    <input type="text" v-model.trim="code" placeholder="Код подтверждения">

                </div>
                <div class="flex justify-between">
                    <button class="px-5 py-2.5 rounded text-white" :disabled="!confirmed"
                            @click="submit"
                            :class="{'bg-green-400 cursor-pointer':confirmed,'bg-gray-500 cursor-default':!confirmed}">
                        Сохранить
                    </button>
                    <button class="px-5 py-2.5 rounded text-white bg-red-600" @click="closeModal">Отменить</button>
                </div>
            </div>
        </div>

    </Modalwindow>
</template>

<script>
import Modalwindow from '@/components/common/ModalWindow'
import CloseButton from '@/components/common/CloseButton'
import {getRandomInt} from "@/helpers/functions";
import {validationMixin} from "vuelidate";
import {required} from 'vuelidate/lib/validators';
import moment from "moment";

export default {
    name: "PutMoneyModal",
    mixins: [validationMixin],
    components: {
        Modalwindow, CloseButton
    },
    props: {
        showModal: {
            type: Boolean,
            required: true
        },
        organization: {
            required: true,
            type: Object,
        }
    },
    data: () => ({
        number: null,
        unp: null,
        sum: null,
        details: null,
        date: null,

        code: null,
        code_text: null,
        balance: {
            balance: null,
            updated: null,
        },
    }),
    computed: {
        confirmed() {
            return Number.parseInt(this.code) === Number.parseInt(this.code_text);
        },
    },
    watch: {
        organization(nv) {
          if(Object.keys(nv).length){
            this.getBalance()
            this.code_text = getRandomInt(1000, 9999)
            this.unp = this.organization.uf_unp
          }
        }
    },
    methods: {
        closeModal() {
            this.number = null
            this.unp = null
            this.sum = null
            this.details = null
            this.date = null
            this.code = null
            this.code_text = null
            this.$emit('update:showModal', false)
        },
        async getBalance() {
            this.$store.dispatch('admin/getBalanceByUnp', this.organization.uf_unp).then(res => {
              this.balance = res.data.data
            })
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                const date = moment(this.date).format('DD.MM.YYYY')

                let payload = {
                    number: this.number,
                    unp: this.unp,
                    sum: this.sum,
                    details: this.details,
                    date: date,
                    operation: "credit"
                }

                this.$store.dispatch('clientPayments/updateClientPayment', payload).then(res => {
                    this.$notify({
                        title: 'Успешно',
                        text: res.data.data,
                        type: 'success'
                    })
                    this.$v.$reset()
                    this.closeModal()

                }).catch(err => {
                    this.$notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
            }
        }

    },
    validations: {
        number: {required},
        unp: {required},
        sum: {required},
        date: {required},
        details: {required},
    }

}
</script>

<style scoped>
.modal__content {
    @apply lg:w-1/2 sm:w-full  dark:bg-black bg-white dark:text-white text-black  p-10 rounded relative lg:border-l-2
}

.modal__header {
    @apply flex justify-between  w-full
}

table {
    @apply w-full text-left table dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm;
}

thead {
    @apply dark:bg-gray-800 bg-white text-gray-500
}

tbody {
    @apply dark:text-gray-400 text-gray-600
}

input {
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5  text-base dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white
}

.confirm input {
    @apply w-1/2;
}
</style>